import { Button, Card, Form, Table, DatePicker, message } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import {
  getPatientInvestigations,
  createPatientInvestigation,
  updatePatientInvestigation,
} from "Api";
import dayjs from "dayjs";
import { INVESTIGATIONS, CXR } from "Constants";
import Joi from "joi";

const Investigations = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);

  const unitRef = useRef(null);
  const resultCXRRef = useRef(null);
  const resultRef = useRef(null);

  const [investigations, setInvestigations] = useState([]);
  const [id, setId] = useState(null);

  const schema = Joi.object({
    type: Joi.string().required().label("Investigation Type"),
    investigation: Joi.string().required().label("Investigation Name"),
    report_no: Joi.string().allow(null, ""),
    test_date: Joi.date().optional().allow(null),
    result_date: Joi.date().optional().allow(null),
    result: Joi.string().optional().allow(null),
    unit: Joi.string().optional().allow(null),
  });

  const loadInvestigations = () => {
    if (selected) {
      getPatientInvestigations({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setInvestigations(data);
        }
      });
    }
  };

  const onFinish = (values) => {
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      error.details.forEach((detail) => {
        message.error(detail.message);
      });
      return;
    }

    if (selected && values) {
      const body = {
        ltbi_no: selected.ltbi_no,
        type: values.type,
        investigation: values.investigation,
        report_no: values.report_no,
        test_date: values?.test_date
          ? values.test_date.format("YYYY-MM-DD")
          : null,
        result_date: values?.result_date
          ? values.result_date.format("YYYY-MM-DD")
          : null,
        result: values.result,
        unit: values?.unit,
      };

      const handleResponse = (data) => {
        setId(null);
        loadInvestigations();
        form.resetFields();
        message.success(
          id
            ? "Investigation updated successfully!"
            : "Investigation created successfully!"
        );
      };

      const handleError = (error) => {
        console.error("Error:", error);
        message.error("Failed to save investigation. Please try again.");
      };

      if (id) {
        body.id = id;
        updatePatientInvestigation(body)
          .then(handleResponse)
          .catch(handleError);
      } else {
        createPatientInvestigation(body)
          .then(handleResponse)
          .catch(handleError);
      }
    }
  };

  useEffect(() => {
    if (selected) {
      loadInvestigations();
    } else {
      form.resetFields();
    }
  }, [selected]);

  const handleEdit = (record) => {
    setId(record?.id ?? null);
    Object.entries(record).forEach(([key, value]) => {
      form.setFieldValue(
        key,
        (key === "test_date" || key === "result_date") && value
          ? dayjs(value)
          : value
      );
      if (key === "type") {
        unitRef.current.style.display = value === "OTHER" ? "none" : "block";
        resultCXRRef.current.style.display = value === "CXR" ? "block" : "none";
        resultRef.current.style.display = value !== "CXR" ? "block" : "none";
      }
    });
  };

  const handleReset = () => {
    setId(null);
    form.resetFields();
  };

  const columns = [
    {
      title: "Report Name",
      dataIndex: "investigation",
      key: "investigation",
    },
    {
      title: "Report No.",
      dataIndex: "report_no",
      key: "report_no",
    },
    {
      title: "Test Date",
      dataIndex: "test_date",
      key: "test_date",
      render: (text) => (text ? dayjs(text).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (_, record) => {
        if (record.type === "CXR") {
          const foundObject = CXR.find((item) => item.value === record.result);
          return foundObject ? <b>{foundObject.text}</b> : "";
        } else {
          return record.result;
        }
      },
    },
    {
      title: "Result Date",
      dataIndex: "result_date",
      key: "result_date",
      render: (text) => (text ? dayjs(text).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        !selected?.finished && (
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormSelect
              label="Investigation Type"
              name="type"
              setVal={(val) => {
                unitRef.current.style.display =
                  val === "OTHER" ? "none" : "block";
                resultCXRRef.current.style.display =
                  val === "CXR" ? "block" : "none";
                resultRef.current.style.display =
                  val !== "CXR" ? "block" : "none";
              }}
              options={INVESTIGATIONS}
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Report Name"
              name="investigation"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Report Number"
              name="report_no"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <Form.Item label="Test Date" name="test_date">
              <DatePicker
                style={{ width: "100%" }}
                disabled={selected?.finished}
              />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label="Result Date" name="result_date">
              <DatePicker
                style={{ width: "100%" }}
                disabled={selected?.finished}
              />
            </Form.Item>
          </div>

          <div className="col-md-3" ref={resultRef}>
            <FormInput
              label="Result"
              name="result"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3" ref={resultCXRRef}>
            <FormRadioGroup
              label="Result"
              name="result"
              options={CXR}
              disabled={selected?.finished}
            />
          </div>

          <div className="col-md-3" ref={unitRef}>
            <FormInput label="Unit" name="unit" disabled={selected?.finished} />
          </div>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={selected?.finished}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={handleReset}
              style={{ marginLeft: 8 }}
              disabled={selected?.finished}
            >
              Clear
            </Button>
          </Form.Item>
          {!!selected?.finished && (
            <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
              Treatment already finished
            </p>
          )}
        </div>
      </Card>

      {investigations && investigations.length > 0 ? (
        <Card className="p-2">
          <Table
            columns={columns}
            dataSource={investigations}
            scroll={{ x: 1400 }}
            rowKey="id"
            sticky
            className="mt-3"
          />
        </Card>
      ) : null}
    </Form>
  );
};

export default Investigations;
